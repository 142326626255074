<template>
  <div>
    <section class="banner-sec" id="home">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="banner-info">
              <span>STEP INTO METAVERSE</span>
              <h1 class="mb-0">
                Connect, Innovate, and Thrive in Our Dynamic Metaverse
              </h1>
            </div>
            <!--banner-info-->

            <div class="button-box">
              <a class="text-decoratiom-none" href="https://app.valoraland.com">
              <button class="btn btn-secondary" id="transparent">
                
                Get Started
              </button>
              </a>
              <button class="btn btn-primary" id="notice">
                Community Notice
              </button>
            </div>
            <!--button-box-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6 order-first order-sm-1">
            <div class="banner-image">
              <img
                src="../assets/images/banner.png"
                alt="banner"
                class="img-fluid"
              />
            </div>
            <!--banner-image-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->

      <img src="../assets/icons/blur.png" alt="" class="blur-icon" />
    </section>
    <!--banner-sec-->

    <section class="about-sec" id="about">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="about-image">
              <img
                src="../assets/images/about.png"
                alt="about-icon"
                class="img-fluid"
                loading="lazy"
              />
            </div>
            <!--about-image-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="about-info">
              <h2 class="mb-0 pb-3">
                Step into Valoraland: The Land Polling Strategy Game for Land Trading, Collecting, Reselling, and Farming
              </h2>

              <button class="btn btn-primary">
                Learn More 
              </button>
            </div>
            <!--baner-info-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--about-sec-->

    <section class="roadmap-sec" id="roadmap">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6 roadmap-col">
            <div class="heading">
              <h2 class="mb-0">Roadmap</h2>
            </div>
            <!--heading-->

            <div class="vertical-line"></div>

            <div
              class="roadmap-box"
              v-for="(roadmap, index) in RoadmapData"
              :key="index"
            >
              <h3 class="mb-0">{{ roadmap.sr }}</h3>
              <div class="map-box">
                <h2 class="mb-0 pb-2">{{ roadmap.title }}</h2>

                <p class="mb-0">
                  {{ roadmap.para }}
                </p>

                <span>Done</span>
              </div>
              <!--map-box-->
            </div>
            <!--roadmap-box-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6 order-first order-sm-1">
            <div class="roadmap-banner">
              <img
                src="../assets/images/roadmap.png"
                alt="roadmap"
                class="img-fluid"
                loading="lazy"
              />
            </div>
            <!--roadmap-banner-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--roadmap-sec-->

    <section class="slider-sec" id="collection">
      <swiper
    :slidesPerView="3"
    :spaceBetween="30"
    :loop="true"
    :pagination="{
      clickable: true,
    }"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"

    :breakpoints="{
      '320': {
        slidesPerView: 1,
        spaceBetween: 0,
      },

      '481': {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      '768': {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      '1024': {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    }"
    :modules="modules"
    class="mySwiper"
  >
  <swiper-slide v-for="(slide, index) in SlideData" :key="index">
        <div class="slider-card">
          <img
            :src="require(`../assets/images/${slide.image}`)"
            alt="slider image"
            class="img-fluid"
            loading="lazy"
          />
        </div>
        <!--slider-card-->
      </swiper-slide>
  </swiper>
    </section>
    <!--slider-sec-->

    <section class="faq-sec" id="faq">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-5 col-xl-5">
            <div class="faq-image">
              <img
                src="../assets/images/faq.png"
                alt="faq"
                loading="lazy"
                class="img-fluid"
              />
            </div>
            <!--faq-image-->
          </div>
          <!--col-md-4 col-lg-4 col-xl-4-->

          <div class="col-md-7 col-lg-7 col-xl-7">
            <div class="accordion" id="accordionExample">
              <div
                class="accordion-item"
                v-for="(faq, index) in FaqData"
                :key="index"
              >
                <h2 class="accordion-header">
                  <button
                    class="shadow-none"
                    :class="
                      index == 0
                        ? 'accordion-button'
                        : 'accordion-button collapsed'
                    "
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapseOne' + index"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {{ faq.title }}
                  </button>
                </h2>
                <div
                  :id="'collapseOne' + index"
                  :class="
                    index == 0
                      ? 'accordion-collapse collapse show'
                      : 'accordion-collapse collapse'
                  "
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    {{ faq.para }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--faq-sec-->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import FaqJson from "@/assets/Json/Faq.json";
export default {
  name: "HomeView",
  components: {
    Swiper,
    SwiperSlide,
  },




  setup() {
    
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Autoplay],
      };

      
    },

  data() {
    return {
      RoadmapData: [
        {
          sr: "01",
          title: "Conceptualization and Design",
          para: "Lay the foundation by conceptualizing the metaverse's core elements and designing its immersive landscapes, characters, and structures.",
        },

        {
          sr: "02",
          title: "Blockchain Integration",
          para: "Integrate blockchain technology, ensuring secure ownership, transparency, and interoperability for digital assets within the metaverse.",
        },

        {
          sr: "03",
          title: "Development and Testing",
          para: "Execute the vision by developing the metaverse's interactive features, functionalities, and mechanics, followed by rigorous testing for seamless user experiences.",
        },

        {
          sr: "04",
          title: "Beta Launch and Feedback",
          para: "Launch a beta version, inviting early users to explore, provide feedback, and identify areas for enhancement, refining the metaverse's mechanics.",
        },

        {
          sr: "05",
          title: "Content Expansion and Engagement",
          para: "Expand the metaverse's content with regular updates, introducing new features, environments, and quests to keep users engaged and excited.",
        },

        {
          sr: "06",
          title: "Community Collaboration and Scaling",
          para: "Collaborate with the community, allowing creators to contribute to the metaverse's growth. Scale the platform to accommodate a growing user base.",
        },
      ],

      SlideData: [
        {
          image: "slide-1.png",
        },
        {
          image: "slide-2.png",
        },

        {
          image: "slide-3.png",
        },

        {
          image: "slide-4.png",
        },

        {
          image: "slide-5.png",
        },

        {
          image: "slide-6.png",
        },
      ],

      FaqData: FaqJson.FaqData,
    };
  },
};
</script>

<style scoped>
/**=========== BANNER SEC CSS START ===========**/
.banner-sec {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-info {
  margin-bottom: 30px;
}

.banner-info span {
  color: #00ffff;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  padding-bottom: 10px;
}

.banner-info h1 {
  color: var(--white);
  text-transform: capitalize;
  line-height: 55px;
  font-weight: 600;
}

.banner-image img {
  position: absolute;
  bottom: 10%;
  right: 10%;
}

img.blur-icon {
  position: absolute;
  margin-top: -80%;
  width: 130vw;
  z-index: -1;
  top: 0;
  left: -15%;
}

.button-box {
  display: flex;
  align-items: center;
  gap: 15px;
}
.button-box button.btn {
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  height: 60px;
  font-size: 0.875rem;
  min-height: 3rem;
  align-items: center;
  gap: 5px;
  font-family: "Poppins";
  min-width: 230px;
  justify-content: center;
  border-radius: 15px;
  font-weight: 600;
}

button#transparent {
  background: var(--gradient-2);
  border: 2px solid var(--purple);
}

button#notice {
  background: var(--gradient-3);
  border: none;
}

/**=========== BANNER SEC CSS FINISH ===========**/

/**=========== ABOUT SEC CSS START ===========**/

.about-image img {
  max-width: 770px;
}

.about-info {
  padding-left: 25%;
}

.about-info h2 {
  font-size: 30px;
  color: var(--white);
  line-height: 50px;
  font-weight: 700;
}

.about-info .btn-primary {
  min-height: 55px;
  font-size: 16px;
  font-family: "Poppins";
  width: 100%;
  font-weight: 600;
  background: var(--gradient-3);
  border: none;
}
/**=========== ABOUT SEC CSS FINISH ===========**/

/**=========== ROADMAP SEC CSS START ===========**/

.roadmap-col {
  position: relative;
}

.heading h2 {
  font-size: 48px;
  color: var(--white);
  font-weight: 600;
}

.heading {
  margin-bottom: 40px;
}

.roadmap-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  margin-bottom: 35px;
}

.map-box {
  padding: 35px 20px;
  background: var(--gradient-4);
  border-radius: 12px;
  position: relative;
}

.map-box h2 {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.map-box p {
  color: var(--map-color);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.map-box span {
  position: absolute;
  top: 4px;
  right: 10px;
  color: var(--green);
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
}

.roadmap-box h3 {
  font-size: 25px;
  color: #00ffff;
  font-weight: 600;
}

.vertical-line {
  width: 1px;
  height: calc(100% - 108px);
  top: 177px;
  margin-bottom: 5%;
  border-left: 1px solid var(--border);
  position: absolute;
  z-index: -1;
  max-height: 77%;
  left: 32px;
}

.roadmap-banner {
  position: sticky;
  top: 0;
}
/**=========== ROADMAP SEC CSS FINISH ===========**/

/**=========== FAQ SEC CSS START ===========**/
.accordion-item {
  background: transparent;
  border-top: none;
  border-left: 0;
  border-right: 0;
  border-radius: 0 !important;
  border-bottom: 2px solid var(--purple);
  margin-bottom: 18px;
}

button.accordion-button {
  background: transparent;
  background: transparent;
  color: var(--white);
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 15px;
}

.accordion-body {
  color: var(--map-color);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/icons/minus.svg);
}

.accordion-button::after {
  background-image: url(../assets/icons/plus.svg);
}

/**=========== FAQ SEC CSS FINISH ===========**/

@media all and (min-width: 1200px) and (max-width: 1399px) {
  .banner-image img {
    position: static;
  }

  .about-image img {
    max-width: 600px;
}
.about-info h2 {
  font-size: 25px;
}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  .banner-image img {
    position: static;
  }
  .about-image img {
    max-width: 100%;
  }

  .about-info {
    padding-left: 0;
  }
}


@media all and (min-width: 768px) and (max-width: 991px) {

  .banner-sec {
    min-height: auto;
   padding-top: 150px;
  }
  .banner-image img {
    position: static;
  }

  .banner-info h1 {
    line-height: 38px;
    font-size: 24px;
    letter-spacing: 1px;
  }

  .button-box {
    display: block;
  }

  button#transparent {
    margin-bottom: 15px;
  }


  .about-image img {
    max-width: 100%;
  }

  .about-info {
    padding: 0;
  }
  .about-info h2 br {
    display: none;
  }

  .about-info h2 {
    line-height: 25px;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 20px;
  }
}
 
@media all and (min-width: 320px) and (max-width: 767px) {

  .banner-sec {
    
    padding-top: 140px;
  }
  .banner-image img {
    position: static;
  }

  .banner-info {
    text-align: center;
  }

  .banner-info h1 {
    line-height: 32px;
    font-size: 18px;
    letter-spacing: 3px;
  }

  .button-box {
    display: block;
    text-align: center;
  }

  button#transparent {
    margin-bottom: 15px;
  }

  .about-image img {
    max-width: 100%;
  }

  .about-info {
    padding-left: 0;
  }

  .about-info h2 br {
    display: none;
  }

  .about-info h2 {
    font-size: 18px;
    line-height: 31px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
  }

  .vertical-line {
    display: none;
  }
  .roadmap-box {
    margin-bottom: 25px;
    display: block;
  }
  .roadmap-box h3 {
    padding-bottom: 10px;
  }

  .heading h2 {
    text-align: center;
    font-size: 25px;
  }
}
</style>
