<template>
  <div>
    <Header />
    <router-view />
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer
  },
};
</script>

<style>
#app {
  font-family: "Monument", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-image: var(--gradient) !important;

  height: 100vh;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: 100%;
  background: #12062b !important;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}


@font-face {
  font-family: Monument;
  src: url(./assets/fonts/MonumentExtended-Regular.otf);
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: Monument;
  src: url(./assets/fonts/MonumentExtended-Ultrabold.otf);
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
  font-weight: 700;
  font-display: swap;
}

:root {
  --white: #fff;
  --black: #000;
  --gradient: linear-gradient(
    179.11deg,
    #132a57 -23.14%,
    rgba(28, 14, 47, 0.9) 166.95%
  );
  --gradient-2: linear-gradient(
    252.53deg,
    #271f38 -21.28%,
    rgba(127, 67, 255, 0) 61.97%
  );
  --gradient-3: linear-gradient(
    258.56deg,
    rgba(4, 255, 255, 0.9) -4.71%,
    #6800ec 67.29%
  );
  --purple: rgb(102 2 255);

  --gradient-4:linear-gradient(225deg,#3d2384,rgba(28,14,47,0));
  --map-color:#84b5ff;
  --green:#00ff80;
  --border:#172863;
}

section {
  padding: 80px 0;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  section {
    padding: 40px 0;
  }
}
</style>
