<template>
  <div>
    <header class="head">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="container">
          <a class="navbar-brand" href="#"
            ><img
              src="../assets/logo.png"
              alt="logo"
              class="img-fluid"
              style="max-width: 160px"
          /></a>
          <button
            class="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item" @click="closeToggle">
                <a class="nav-link active" aria-current="page" href="#home"
                  >Home</a
                >
              </li>
              <li class="nav-item" @click="closeToggle">
                <a class="nav-link" href="#about">About</a>
              </li>

              <li class="nav-item" @click="closeToggle">
                <a class="nav-link" href="#roadmap">Roadmap</a>
              </li>

              <li class="nav-item" @click="closeToggle">
                <a class="nav-link" href="#collection">Collection</a>
              </li>
              <li class="nav-item" @click="closeToggle">
                <a class="nav-link" href="#faq">FAQ</a>
              </li>
            </ul>
            <div class="right-nav d-flex">
              <div class="social-list">
                <ul class="social-link">
                  <li>
                    <a href="#" target="_blank">
                      <img
                        src="../assets/icons/social-icon-1.svg"
                        alt="social icon"
                        class="img-fluid"
                      />
                    </a>
                  </li>

                  <li>
                    <a href="#" target="_blank">
                      <img
                        src="../assets/icons/social-icon-2.svg"
                        alt="social icon"
                        class="img-fluid"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <img
                        src="../assets/icons/social-icon-3.svg"
                        alt="social icon"
                        class="img-fluid"
                      />
                    </a>
                  </li>
                </ul>
              </div>

              <div class="button-box">
                <a class="text-decoratiom-none" href="https://app.valoraland.com">
                  <button class="btn btn-primary">Launch App</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <!--head-->
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  methods: {
    closeToggle() {
      const menuToggle = document.getElementById("navbarSupportedContent");
      menuToggle.classList.remove("show");
    },

    handleSCroll() {
      console.log(window.scrollY);
      let header = document.querySelector(".head");
      if (window.scrollY > 100 && !header.className.includes("sticky")) {
        header.classList.add("sticky");
      } else if (window.scrollY < 100) {
        header.classList.remove("sticky");
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleSCroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleSCroll);
  },
};
</script>

<style scoped>
header.head {
  padding: 10px 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
}

header.head.sticky {
  background: var(--gradient);
}

a.navbar-brand {
  margin-right: 70px;
}

ul.navbar-nav {
  gap: 18px;
}
ul.navbar-nav li.nav-item a.nav-link {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 600;
}

.right-nav {
  align-items: center;
  gap: 30px;
}

.social-list ul.social-link {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.button-box .btn-primary {
  background-color: var(--purple);
  color: #fff;
  border-radius: 6px;
  height: 40px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  border: none;
}

.button-box .btn-primary:active {
  background-color: var(--purple);
}

button.navbar-toggler span.navbar-toggler-icon {
  filter: invert(1);
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  a.navbar-brand {
    margin-right: 30px;
  }

  ul.navbar-nav {
    gap: 10px;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  header.head {
    background: var(--gradient);
  }

  ul.navbar-nav {
    gap: 8px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  header.head {
    background: var(--gradient);
  }

  ul.navbar-nav {
    gap: 8px;
  }
}
</style>
