<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="social-list">
              <ul class="social-link">
                <li>
                  <a href="#" target="_blank">
                    <img
                      src="../assets/icons/social-icon-1.svg"
                      alt="social icon"
                      class="img-fluid"
                    />
                  </a>
                </li>

                <li>
                  <a href="#" target="_blank">
                    <img
                      src="../assets/icons/social-icon-2.svg"
                      alt="social icon"
                      class="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img
                      src="../assets/icons/social-icon-3.svg"
                      alt="social icon"
                      class="img-fluid"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer {
    padding: 30px 0;
}
.social-list ul.social-link {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}


</style>
